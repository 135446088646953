// import React,{useState, useEffect} from 'react';
// import ReactDOM from 'react-dom';
// import { createRoot } from 'react-dom/client';

// //import './layout/DefaultLayout.js';
// import App from './App';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import 'react-toastify/dist/ReactToastify.css';
// import { BrowserRouter } from "react-router-dom";

// //import { resolve } from 'path';
// // MSAL imports
// import {
//   PublicClientApplication,
//   EventType
// } from "@azure/msal-browser";
// import { loginRequest } from './authConfig';

// import { msalConfig } from "./authConfig";
// import { setupAxiosRequestInterceptor, setupAxiosResponseInterceptor } from './components/axiosInterceptors';
// //import 'dotenv/config';

// export const msalInstance = new PublicClientApplication(msalConfig);

// msalInstance.initialize().then(() => {
//   console.log(msalConfig);
//   setupAxiosRequestInterceptor(msalInstance);
//   setupAxiosResponseInterceptor(msalInstance);
//     // Account selection logic is app dependent. Adjust as needed for different use cases.
//     const existingAccount = localStorage.getItem("account");
//     const accounts = msalInstance.getAllAccounts();

//     //if (existingAccount) {
//     //  const activeAccount = accounts.find(acc => acc.username === existingAccount);
//     //  if (activeAccount) {
//     //    msalInstance.setActiveAccount(activeAccount);
//     //    return;
//     //  }
//     //}
//       if (accounts.length > 0) 
//       {
//         msalInstance.setActiveAccount(accounts[0]);
//       }
//     msalInstance.addEventCallback((event) => {
//         if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
//             const payload = event.payload;
//             const account = payload.account;
//             msalInstance.setActiveAccount(account);
//             localStorage.setItem('account', account.username);
//             const GetToken = async () => {
//                 const token = await msalInstance.acquireTokenSilent({ ...loginRequest, account })
//                 localStorage.setItem('token', token.accessToken);
//                 setTimeout(() => {}, 5000);
//             }
//             GetToken();
//         }
//     });

    
    
//     const initializeApp = async () => {
//       try {
//         await msalInstance.initialize();
//         setupAxiosRequestInterceptor(msalInstance);
//         setupAxiosResponseInterceptor(msalInstance);
//       } catch (error) {
//         console.error('Failed to initialize application', error);
//       }
//     };
    
//     const Root = () => {
//       useEffect(() => {
//         initializeApp();
//       }, []);
    
//       return (
//         <BrowserRouter>
//           <App pca={msalInstance}/>
//         </BrowserRouter>
//       );
//     };
    
//     // ReactDOM.render(<Root />, document.getElementById('root'));
//     const container = document.getElementById('root');
//     const root = createRoot(container); // Create a root.
//     root.render(<Root />); // Initial render: Render an element to the root.
// });





import { loginRequest } from './authConfig';
import React,{ useEffect } from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, useNavigate } from "react-router-dom";
import {
    PublicClientApplication,
    EventType,
    EventMessage,
    AuthenticationResult,
} from "@azure/msal-browser";
import { msalConfig } from "./authConfig";
import { MsalProvider } from "@azure/msal-react";
import { setupAxiosRequestInterceptor, setupAxiosResponseInterceptor } from './axiosInterceptors';


const msalInstance = new PublicClientApplication(msalConfig);

//get initialize msalInstance
msalInstance.initialize().then(() => {
  setupAxiosRequestInterceptor(msalInstance);
  setupAxiosResponseInterceptor(msalInstance);
})

const activeAccount = msalInstance.getActiveAccount();

if (!activeAccount) {
    // Account selection
    const accounts = msalInstance.getAllAccounts();
    if (accounts.length > 0) {
        msalInstance.setActiveAccount(accounts[0]);
    }
}


msalInstance.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
      const payload = event.payload;
      const account = payload.account;
      msalInstance.setActiveAccount(account);
      localStorage.setItem('account', account.username);
      const GetToken = async () => {
          const token = await msalInstance.acquireTokenSilent({ ...loginRequest, account })
          localStorage.setItem('token', token.accessToken);
          setTimeout(() => {}, 5000);
      }
      GetToken();
  }

});
//enable account storage event
msalInstance.enableAccountStorageEvents();


//App main component
function AppComponent() {
    const navigate = useNavigate();

    useEffect(() => {


      msalInstance.handleRedirectPromise().then(() => {
        console.log("***********MSAL Instance******************");
        if (msalInstance.getAllAccounts().length === 0) {
          msalInstance.loginRedirect({
            scopes: ['api://c397f3f4-6ead-4c1e-9b6f-927ce4b5b848/wetsample'], // Specify the scopes you need
            //prompt: 'select_account' // Forces user to choose account even if logged in
          });
        } else {
            msalInstance.acquireTokenSilent({
                scopes: ['api://c397f3f4-6ead-4c1e-9b6f-927ce4b5b848/wetsample'] // Specify the scopes you need
              }).then(response => {
              console.log(response.accessToken);
              //token = response.accessToken;
              localStorage.token = response.accessToken;
              }).catch(error => {
                console.error('Failed to acquire token:', error);
              });
        }
      });
        // msalInstance.handleRedirectPromise().then((response) => {
        //     if (response && response.account) {
        //         // User is authenticated, you can proceed to  app
        //         navigate("/Dashboard", { replace: true });
        //     }
        // });
        // // Check if the user is already signed in
        // const account = msalInstance.getActiveAccount();
        // if (account) {
        //     // User is already signed in, you can proceed to  app
        //     navigate("/Dashboard", { replace: true });
        // } else {
        //     // If the user is not signed in, initiate the login process
        //     msalInstance.initialize();
        // }
    }, []);

    return <App instance={msalInstance} token={"token"} />;
}

// const container = document.getElementById("root");
// const root = createRoot(container!);

// root.render(
//     <BrowserRouter>
//         <MsalProvider instance={msalInstance}>
//             <AppComponent />
//         </MsalProvider>
//     </BrowserRouter>,
// );
createRoot(document.getElementById('root')).render(
  <BrowserRouter>
        <MsalProvider instance={msalInstance}>
        <AppComponent />

        </MsalProvider>
    </BrowserRouter>,

);

reportWebVitals();
