import React, {useEffect, useState} from 'react'
import './App.css'
import { Route, useNavigate, Routes } from 'react-router-dom'
import './scss/style.scss'
import { Provider } from 'react-redux'
import store from './store'
import {
  MsalProvider,
  AuthenticatedTemplate,
  useMsal,
  UnauthenticatedTemplate,
} from '@azure/msal-react'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

const MainContent = () => {
  /**
   * useMsal is a hook that returns the PublicClientApplication instance.
   * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/hooks.md
   */
  const { instance } = useMsal()
  const activeAccount = instance.getActiveAccount()
//   const navigate = useNavigate();

//   useEffect(() => {

//     const accounts = instance.getAllAccounts();
//     instance.handleRedirectPromise().then((response) =>
//     {
//       if (accounts.length === 0) {
//         // Check if we're on the redirect URI, in which case we don't want to navigate
//         // Adjust this as necessary to your specific URI
//         if (window.location.pathname !== "/dashboard") {
//             navigate("/login");
//         }
//     } else {
//       navigate(window.location.pathname);
//     }
//     });
//     // If there's an account in MSAL's cache, it means the user is authenticated

// }, [instance, navigate]);

  /**
   * Most applications will need to conditionally render certain components based on whether a user is signed in or not.
   * msal-react provides 2 easy ways to do this. AuthenticatedTemplate and UnauthenticatedTemplate components will
   * only render their children if a user is authenticated or unauthenticated, respectively. For more, visit:
   * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
   */
  return (
    <div className="App">
      {/* <ToastContainer /> */}
      <AuthenticatedTemplate>
        {activeAccount ? (
          <Provider store={store}>
            <Routes>
              <Route path="*" name="Dashboard" element={<DefaultLayout />} />
            </Routes>
          </Provider>
        ) : null}
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <h5 className="card-title">Please sign-in to access the application.</h5>
      </UnauthenticatedTemplate>

    </div>
  )
}
// eslint-disable-next-line react/prop-types
const App = ({ instance, token }) => {
  return (

    <MsalProvider instance={instance}>
      {/* <ToastContainer /> */}
      <MainContent />
    </MsalProvider>

  )
}
export default App
